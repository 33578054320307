/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowServiceIcon,
  ImageSection2,
  ImageSectionOurVision,
  ImageSnowFlake,
  LogoBinance,
  LogoBinanceMobile,
  LogoBitget,
  LogoBitgetMobile,
  LogoGuru,
  LogoGuruMobile,
  LogoHSCT,
  LogoHSCTMobile,
  LogoOkx,
  LogoOkxMobile,
  LogoSota,
  LogoSotaMobile,
  LogoSui,
  LogoSuiMobile,
  LogoTitan,
  LogoTitanMobile,
  LogoWarppipe,
} from '@/assets';
import { socialNetworks } from '@/constants/home';
import useResponsive from '@/hooks/useResponsive';
import { ArrowRight, HambergerMenu } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const imageOurPortfolio = [
    LogoTitan,
    LogoGuru,
    LogoHSCT,
    LogoBinance,
    LogoOkx,
    LogoSui,
    LogoBitget,
    LogoSota,
  ];
  const imageOurPortfolioMobile = [
    LogoTitanMobile,
    LogoGuruMobile,
    LogoHSCTMobile,
    LogoBinanceMobile,
    LogoOkxMobile,
    LogoSuiMobile,
    LogoBitgetMobile,
    LogoSotaMobile,
  ];
  const listService = [
    {
      title: 'Software Service',
      description:
        'Warppipe is a leading provider of technology services with youth, enthusiasm, commitment to bring the best quality in IT. We deliver sustainable Software Development, including Web/App, Blockchain, AI & Machine Learning, ERP with cost-effective solutions.',
      textLink: 'More',
    },
    {
      title: 'Software Developmemt',
      description:
        'Warppipe is a developer and co-founder of technology products, with the mindset of optimizing the experience and shortening the road to success. With strengths in Blockchain, AI and economics, such products can be mentioned as: Titan Trading Platform, Moneybot App, AI Assistant,...',
      textLink: 'What we do?',
    },
    {
      title: 'News & Agency',
      description:
        'Warppipe is also the unit that provides the latest knowledge and news about Blockchain. Provide marketing solutions services. As a unit implementing production, creative, marketing communication campaigns.',
      textLink: 'What we do?',
    },
    {
      title: 'Investment',
      description:
        'As a unit that wishes to contribute to the incubation of young startups. Diversify the product ecosystem.',
      textLink: 'What we do?',
    },
  ];
  const isMobile = useResponsive();
  const [isOpen, setIsOpen] = useState(false);
  const handleScrollToServices = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false);
  };
  const renderPortfolio = useMemo(() => {
    return isMobile ? imageOurPortfolioMobile : imageOurPortfolio;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
  return (
    <main className="warppipe-lading-page">
      <div className="box-top">
        <div className="container-fluid">
          <header className="header-lading-page">
            <img src={LogoWarppipe} alt="logo" width={151} height={28} />
            <nav>
              <Link to="/">Home</Link>
              <a href="" onClick={handleScrollToServices}>
                Expertise & Service
              </a>
              <a href="https://coinmoi.com">News</a>
            </nav>
            {isMobile ? (
              <HambergerMenu color="#f2f4f7" onClick={() => setIsOpen(!isOpen)} />
            ) : (
              <a
                href="https://calendar.app.google/YUjdviPwL9W34Ma78"
                rel="noopener noreferrer"
                target="_blank"
                className="btn-book"
              >
                Book a Free Call
              </a>
            )}
            {isOpen && (
              <div className="list-menu-mobile">
                <Link to="/">Home</Link>
                <a href="" onClick={handleScrollToServices}>
                  Expertise & Service
                </a>
                <a href="https://coinmoi.com">News</a>
                <a
                  href="https://calendar.app.google/YUjdviPwL9W34Ma78"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn-book"
                >
                  Book a Free Call
                </a>
              </div>
            )}
          </header>
          <div className="content-top-lading-page">
            <h1 className="title-lading-page">Shorten The Way To Success</h1>
            <div className="description-lading-page">
              Software Service - Development - Investment
            </div>
          </div>
        </div>
      </div>
      <section className="section-2">
        <div className="container-fluid">
          <div className="content-section">
            <span>
              With nearly 4 years of development experience, we have gathered many excellent
              individuals to create a soad team. Provide services to more than 10 countries around
              the world, especially Korea, Russia and China.
            </span>
            <span className="line-gradient">
              {' '}
              With many projects in different fields, such as Finance, Blockchain, Education,
              E-Commerce,...
            </span>
          </div>
          <div className="image-section">
            <img src={ImageSection2} alt="image-section-2" />
          </div>
        </div>
      </section>
      <section id="services">
        <h2 className="title-section">Services at Warppipe</h2>
        <div className="list-service-desktop">
          {listService.map((item, index) => (
            <div className="item-service" key={index}>
              <div className="container-fluid">
                <div className="content">
                  <div className="title-and-link">
                    <h3 className="title-service">{item.title}</h3>
                    <div className="btn-service">
                      <span>{item.textLink}</span>
                      <img src={ArrowServiceIcon} alt="" />
                    </div>
                  </div>
                  <p className="description-service">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="list-service-mobile">
          {listService.map((item, index) => (
            <div className="item-service" key={index}>
              <div className="container-fluid">
                <div className="content">
                  <h3 className="title-service">{item.title}</h3>
                  <p className="description-service">{item.description}</p>
                  <div className="btn-service">
                    <span>{item.textLink}</span>
                    <img src={ArrowServiceIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="our-vision">
        <div className="container-fluid">
          <div className="content">
            <p className="title-our-vision">Our Vision</p>
            <p className="desc">
              Become a leader, use technology for life and business, and optimize the process of
              generating profits.
            </p>
            <div className="image-section">
              <img src={ImageSectionOurVision} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="our-mission">
        <div className="container-fluid">
          <p className="title-our-mission">Our Mission</p>
          <p className="desc">
            Accelerate the mass adoption of emerging technologies through optimizing the experience,
            beyond what was expected and expedient access to global market resources.
          </p>
        </div>
        <div className="image-section">
          <img src={ImageSnowFlake} alt="" />
        </div>
      </section>
      <section className="our-portfolio">
        <div className="container-fluid">
          <p className="title-our-portfolio">Our Portfolio Companies and Clients</p>
          <div className="list-partner">
            {renderPortfolio.map((item, index) => (
              <img src={item} alt="" key={index} />
            ))}
          </div>
        </div>
      </section>
      <section className="join-newsletter">
        <div className="container-fluid">
          <div className="content">
            <div className="left">
              <p className="title-section">Join Newsletter</p>
              <p className="desc">Subscribe to our free newsletter and follow us</p>
            </div>
            <ArrowRight size="168" color="#EAEAEA" />
          </div>
        </div>
      </section>
      <footer className="footer-lading-page">
        <div className="container-fluid">
          <div className="content">
            <div className="left">
              <Link to="">
                <img src={LogoWarppipe} alt="" width={129} height={24} />
              </Link>
              <p className="desc">2023 Warppipe Joint Stock Company</p>
            </div>
            <div className="right">
              {socialNetworks.map((item, index) => (
                <a href={item.url} key={index} rel="noopener noreferrer" target="_blank">
                  <img src={item.icon} alt="" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Home;
